import { Component, Fragment } from 'react';
import WelcomeBar from './WelcomeBar';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import { Navigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import QrCodeIcon from '@mui/icons-material/QrCode';
import IconButton from '@mui/material/IconButton';
import QRCode from "react-qr-code";
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';

class Make extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            anchorElUserMenu: null,

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,

            targeturl: '',
            qrcodebuttonenabled: false,
            qrcodebuttonclicked: false,

            withLogin: false
        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleChangeTargetUrl = this.handleChangeTargetUrl.bind(this);
        this.handleClickQrCodeCreate = this.handleClickQrCodeCreate.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }   
        var config = require('../config.json');
        var withLogin = config.with_login;
        this.setState({ withLogin: withLogin});

        this.setState({ isloading: false});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChangeTargetUrl(event){
        this.setState({ targeturl: event.target.value }, function(){
            if (event.target.value === null || event.target.value === ''){
                this.setState({ qrcodebuttonenabled: false});
            } else {
                this.setState({ qrcodebuttonenabled: true});
            }
        });
    }

    handleClickQrCodeCreate(event){
        this.setState({ qrcodebuttonclicked: true}, function(){
            var svg = document.getElementById("QrCodeImage");
            var serializer = new XMLSerializer();
            var source = serializer.serializeToString(svg);
            if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
                source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
            }
            if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
                source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
            }
            source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
            var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
            var el = document.getElementById("QrCodeDownload");
            el.href = url;
            el.setAttribute("download", "qrcode.svg");
            var img = document.getElementById("QrCodeImagePng");
            img.src = url;            
            //Aggiunta 17/02/22 - Formato QrCode png
            var canvas = document.getElementById("canvas");
            var ctx = canvas.getContext("2d");
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            var image = new Image();
            image.onload = function() {
                ctx.drawImage(img, 10, 10);
                var png = canvas.toDataURL("image/png");
                el.href = png;
                el.setAttribute("download", "qrcode.png");
            }
            image.src = url;            
            //
        });
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        this.setState({ user_email: ''});
        this.setState({ nome: ''});
        this.setState({ cognome: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
    } 

    render() {

        const autenticato = this.state.authorization !== '';
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const avatarletter = nome.charAt(0);
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const isloading = this.state.isloading;
        const error = this.state.error;
        const errormessage = this.state.errormessage;

        const qrcodebuttonenabled = this.state.qrcodebuttonenabled;
        const qrcodebuttonclicked = this.state.qrcodebuttonclicked;
        const targeturl = this.state.targeturl;

        const withLogin = this.state.withLogin;
        
        return (
            <div>
                { ((withLogin && autenticato) || (withLogin === false)) && (
                    <Helmet>
                        <title>QrCode Make&Read - Scansiona QrCode</title>
                        <meta name="description" content="Scansiona QrCode" data-react-helmet="true"/>
                    </Helmet> 
                )}
                {(((withLogin && autenticato) || withLogin === false) && isloading === false) && ( 
                <div className="App">
                    <header className="App-header-home">
                    <WelcomeBar avatarletter={avatarletter} logout={this.handleLogoutClick}></WelcomeBar>
                    </header>
                    <Paper className="App-body">
                        <p>Inserisci qui sotto il testo da codificare</p>
                        <TextField
                           id="targeturl"
                           variant="standard"
                           multiline
                           /* InputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                           }} */
                           inputProps={{ maxLength: 700 }}
                           style={{
                               width: "100%"
                           }}
                           onChange={this.handleChangeTargetUrl}
                        />
                        <br /><br />
                        { qrcodebuttonenabled === false ? (
                            <IconButton
                                id="qrcodebutton"
                                color="primary"
                                aria-label="sendurl"
                                disabled
                            >
                                <QrCodeIcon fontSize="large" />
                            </IconButton>   
                        ) : (
                            <IconButton
                                id="qrcodebutton"
                                color="primary"
                                aria-label="sendurl"
                                onClick={this.handleClickQrCodeCreate}
                            >
                                <QrCodeIcon />
                            </IconButton>   
                        )}  
                        { qrcodebuttonclicked === true && (
                            <Fragment>
                                <Box className="QrCodeBox">
                                    <QRCode id="QrCodeImage" value={targeturl} />
                                </Box>
                                <img id="QrCodeImagePng" alt="QrCodeImagePng" src="" style={{display: "none"}}></img>
                                <canvas id="canvas" width="276" height="276"></canvas>
                                <a href="" id="QrCodeDownload" className="QrCodeDownload">
                                    <DownloadIcon fontSize="large"></DownloadIcon>
                                </a>
                            </Fragment>
                        )}                 
                    </Paper>
                </div>
                )}
                { (withLogin && autenticato === false) && ( 
                    <Navigate to="/login" />
                )}
            </div>
        );
    }
}

export default Make;