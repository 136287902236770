import React from 'react';
//import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes, 
  Route
} from "react-router-dom";
import Login from './components/Login';
import Home from './components/Home';
import Register from './components/Register';
import PasswordResetLink from './components/PasswordResetLink';
//Inserire import delle varie pagine dell'app
import Make from './components/Make';
import Read from './components/Read';

function App() {
  return (
    <div>
      <Router>
        <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/passwordresetlink" element={<PasswordResetLink />} />
            {/* Inserire le varie rotte dell'app */}
            <Route path="/make" element={<Make />} />
            <Route path="/read" element={<Read />} />
            {/* --------------------------------- */}
            <Route path="/" element={<Home />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
