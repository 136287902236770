import { Component, Fragment } from 'react';
import WelcomeBar from './WelcomeBar';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import { Navigate, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Scanner from "react-webcam-qr-scanner";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextField from '@mui/material/TextField';
import DataObjectIcon from '@mui/icons-material/DataObject';
import AbcIcon from '@mui/icons-material/Abc';
import DownloadIcon from '@mui/icons-material/Download';

class Read extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            anchorElUserMenu: null,

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,

            codeReaded : false,
            device: '',
            code: '',
            isUrl: false,
            isFormData: false,
            isText: false,
            targetUrl: '',

            withLogin: false

        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleCodeScannerLoad = this.handleCodeScannerLoad.bind(this);
        this.handleCodeScannerDecode = this.handleCodeScannerDecode.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }   
        let devicetype = this.getDeviceType();
        if ((devicetype === "mobile") || (devicetype === "tablet")) {
            this.setState({device: 'mobile'});
        } else {
            this.setState({device: 'desktop'});
        }
        var config = require('../config.json');
        var withLogin = config.with_login;
        this.setState({ withLogin: withLogin});

        this.setState({ isloading: false});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleCodeScannerLoad(mode){
        console.log(mode);
    }

    handleCodeScannerDecode(result){
        if (this.state.codeReaded === false) {
            var codice = result.data;
            this.setState({codeReaded : true});
            this.setState({code : codice});
            //console.log(codice);
            if (this.isValidUrl(codice)){
                this.setState({isUrl : true});  
                this.setState({targetUrl : codice});          
                return;
            }
            if (this.isValidFormData(codice)){
                this.setState({isFormData : true}, function(){
                    var url = "data:text/json;charset=utf-8,"+encodeURIComponent(codice);
                    var el = document.getElementById('QrCodeVisit');
                    el.href = url;
                    el.setAttribute("download", "qrcode.json");
                    this.setState({targetUrl : url});                  
                });
                return;
            }
            this.setState({isText : true}, function(){
                var url = "data:text/plain;charset=utf-8,"+encodeURIComponent(codice);
                var el = document.getElementById('QrCodeVisit');
                el.href = url;
                el.setAttribute("download", "qrcode.txt");
                this.setState({targetUrl : url});                  
            });
        }
    }

    isValidUrl(code){
        let url;
        try {
            url = new URL(code);
        } catch (e) {
            return false;
        }
        return true;
    }

    isValidFormData(code){
        let formdata;
        try {
            formdata = JSON.parse(code); 
        } catch (e) {
            return false;
        }
        return true;
    }

    getDeviceType() {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        this.setState({ user_email: ''});
        this.setState({ nome: ''});
        this.setState({ cognome: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
    }

    render() {

        const autenticato = this.state.authorization !== '';
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const avatarletter = nome.charAt(0);
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const isloading = this.state.isloading;
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const device = this.state.device;
        const codeReaded = this.state.codeReaded;
        const code = this.state.code;
        const targetUrl = this.state.targetUrl;
        const isUrl = this.state.isUrl;
        const isFormData = this.state.isFormData;
        const isText = this.state.isText;

        const withLogin = this.state.withLogin;

        return (
            <div>
                { ((withLogin && autenticato) || (withLogin === false)) && (
                    <Helmet>
                        <title>QrCode Make&Read - Crea QrCode</title>
                        <meta name="description" content="Crea QrCode" data-react-helmet="true"/>
                    </Helmet> 
                )}
                {(((withLogin && autenticato) || withLogin === false) && isloading === false) && ( 
                <div className="App">
                    <header className="App-header-home">
                    <WelcomeBar avatarletter={avatarletter} logout={this.handleLogoutClick}></WelcomeBar>
                    </header>
                    <Paper className="App-body">
                        <div className="Qrcode-container">
                            { (device === 'desktop' && codeReaded === false) && (
                                <Fragment>
                                    <p>Inquadra il QrCode</p>
                                    <Scanner 
                                        className="QrcodeScanner"
                                        onDecode={this.handleCodeScannerDecode}
                                        onScannerLoad={this.handleCodeScannerLoad}
                                        constraints={{ 
                                            audio: false, 
                                            video: { 
                                                facingMode: "user"
                                            } 
                                        }}
                                        captureSize={{ width: 1280, height: 720 }}
                                    />  
                                </Fragment>
                            )}
                            { (device === 'mobile' && codeReaded === false) && (
                                <Fragment>
                                    <p>Inquadra il QrCode</p>
                                    <Scanner 
                                        className="QrCodeScanner"
                                        onDecode={this.handleCodeScannerDecode}
                                        onScannerLoad={this.handleCodeScannerLoad}
                                        constraints={{ 
                                            audio: false, 
                                            video: { 
                                                facingMode: "environment" 
                                            } 
                                        }}
                                        captureSize={{ width: 1280, height: 720 }}
                                        style={{maxHeight: "70vh"}}
                                    />
                                </Fragment>
                            )}
                        </div>
                        { codeReaded && (
                            <Fragment>
                                <p>Anteprima</p>
                                <Box className="QrCodeResult">
                                    <TextField
                                        id="targeturl"
                                        variant="standard"
                                        multiline
                                        inputProps={{ maxLength: 700 }}
                                        style={{
                                            width: "100%"
                                        }}
                                        readOnly
                                        value={code}
                                    />
                                </Box>
                                { isUrl && (
                                    <Fragment>
                                        <p>Risultato della scansione: Link</p>
                                        <a href={targetUrl} id="QrCodeVisit" className="QrCodeVisit">
                                            <OpenInNewIcon fontSize="large"></OpenInNewIcon>
                                        </a>
                                    </Fragment>
                                )}
                                { isFormData && (
                                    <Fragment>
                                        <p>Risultato della scansione: Json</p><DataObjectIcon fontSize="large"></DataObjectIcon>
                                        <a href="" id="QrCodeVisit" className="QrCodeVisit">
                                            <DownloadIcon fontSize="large"></DownloadIcon>
                                        </a>
                                    </Fragment>
                                )}
                                { isText && (
                                    <Fragment>
                                        <p>Risultato della scansione: Testo</p><AbcIcon fontSize="large"></AbcIcon>
                                        <a href="" id="QrCodeVisit" className="QrCodeVisit">
                                            <DownloadIcon fontSize="large"></DownloadIcon>
                                        </a>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Paper>
                </div>
                )}
                { (withLogin && autenticato === false) && ( 
                    <Navigate to="/login" />
                )}
            </div>
        );
    }
}

export default Read;