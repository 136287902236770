import { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';

class WelcomeBar extends Component {

    constructor(props) {
        super(props);
        this.handleLogoutClick = this.props.logout;
    }

    render() {

        return (
            <AppBar position="static" className="WelcomeBar">
                <Toolbar>
                    <table className="WelcomeMenu">
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/">
                                        <IconButton
                                            aria-label="home"
                                        >
                                            <HomeIcon></HomeIcon>    
                                        </IconButton>
                                    </Link>
                                </td>
                                <td>
                                    { this.props.avatarletter && (
                                        <Avatar className="UserAvatar">{this.props.avatarletter}</Avatar>
                                    )}
                                </td>
                                <td style={{width: "60px", textAlign: "center"}}>
                                    { this.props.avatarletter && (
                                        <IconButton
                                            aria-label="home"
                                            onClick={this.handleLogoutClick}
                                        >
                                            <LogoutIcon></LogoutIcon>    
                                        </IconButton>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Toolbar>
            </AppBar>
        );
    }
}

export default WelcomeBar;