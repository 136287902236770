import React, { Component } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import logo from '../icons/logo512.png';
import LogoTitle from './LogoTitle';

class PasswordResetLink extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            email : "",
            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            sendenabled: false,
            sendcompleted: false,
            emailerror: false
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSendClick = this.handleSendClick.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }   

        this.setState({ isloading: false });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requiredFieldsAreFilled() {

        var required_fields = [
            'email'
        ];

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }

    }

    fieldsAreValid() {

        if (this.validateEmail(this.state.email) === false) {
            return false;
        }
        return true;
    }

    validateEmail(value) {
        let email = value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value }, function(){
            let email_is_valid = this.validateEmail(this.state.email);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
    
            if (email_is_valid) {
                this.setState({ emailerror: false });
            } else {
                this.setState({ emailerror: true });
            }
                    
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ sendenabled: true });
            } else {
                this.setState({ sendenabled: false });
            }
        });     
    }

    handleSendClick(event) {

        event.preventDefault();

        var config = require('../config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ error: false });
        this.setState({ isloading: true });

        const apiUrl = api_url_backend;
        let authorization = api_auth_backend;

        let email = this.state.email;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl+"password/forgot", { 
            "email": email
        }, settings).then(res2 => {

            this.setState({ isloading: false }, function(){
                //console.log(res2.data.data); 
            });

            if (res2.data.success) {

                this.setState({ email: ''});
                this.setState({ sendenabled: false});
                this.setState({ sendcompleted: true});
               
            } else {

                if (this._isMounted) {

                    this.setState({ error: true});
                    if (res2.data.data.code === "email_error"){
                        this.setState({ emailerror: true }); 
                    }
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });

                }
            } 

        }).catch (error => {

            if (this._isMounted) {

                this.setState({ error: true});
                if (error.response.data.data.code === "email_error"){
                    this.setState({ emailerror: true }); 
                }
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });
            }

        });
    }

    render() {

        const isloading = this.state.isloading;
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const emailerror = this.state.emailerror;
        const email = this.state.email;
        const sendenabled = this.state.sendenabled;
        const sendcompleted = this.state.sendcompleted;

        return (
            <div>
                { sendcompleted && (
                    <div className="App">
                        <header className="App-header">
                            <LogoTitle></LogoTitle>
                            <br></br>
                            <div className="Home-description">
                                <p>Il link per il ripristino è stato inviato alla tua casella email!</p>
                            </div>
                        </header>
                    </div>
                )}   
                <div className="App">
                    <header className="App-header">
                        <LogoTitle></LogoTitle>
                        <br></br>
                        <div className="Page-title">Ripristina password</div>
                        { isloading && (
                            <CircularProgress className="Progress"/>
                        )}
                        { error && (
                            <div className="Error-message">{ errormessage }</div>
                        )}
                        <div className="Form-label">
                            <label>E-mail</label>
                        </div>
                        <div className="Form-control">
                            { emailerror ? (
                                <TextField id="email" type="email" error helperText="Valore non valido" required variant="standard" onChange={this.handleChangeEmail} value={ email }/>
                            ) : (
                                <TextField id="email" type="email" required variant="standard" onChange={this.handleChangeEmail} value={ email }/>
                            )}
                        </div>
                        <div className="Form-control">
                            {  sendenabled ? (
                                <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleSendClick}>RICHIEDI LINK RIPRISTINO</Button>
                            ) : (
                                <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleSendClick}>RICHIEDI LINK RIPRISTINO</Button>
                            )}    
                        </div>
                    </header>
                </div>
            </div>
        );
    }
}

export default PasswordResetLink;