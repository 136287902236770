import { Component } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import LogoTitle from './LogoTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class Register extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            email : "",
            password : "",
            passwordconfirm: "",
            nome: "",
            cognome: "",
            authorization: '',

            error: false,
            errormessage: '',
            isloading: false,
            registerenabled: false,

            withLogin: false
        };
        this.handleChangeNome = this.handleChangeNome.bind(this);
        this.handleChangeCognome = this.handleChangeCognome.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({'authorization': Cookies.get('authorization') || ''});
            this.setState({ isloading: true });
        }  
        var config = require('../config.json');
        var withLogin = config.with_login;
        this.setState({ withLogin: withLogin}); 
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requiredFieldsAreFilled() {

        var required_fields;

        required_fields = [
            'nome',
            'cognome',
            'email',
            'password',
            'passwordconfirm'
        ];

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element].length > 0) {
                filled = filled + 1;
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }
    }

    fieldsAreValid() {
        if (this.validateNome(this.state.nome) === false) {
            return false;
        }
        if (this.validateCognome(this.state.cognome) === false) {
            return false;
        }
        if (this.validateEmail(this.state.email) === false) {
            return false;
        }
        if (this.validatePassword(this.state.password) === false) {
            return false;
        }
        if (this.validatePasswordConfirm(this.state.passwordconfirm) === false) {
            return false;
        }
        return true;
    }

    validateNome(value) {
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateCognome(value) {
        let cognome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateEmail(value) {
        let email = value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validatePassword(value) {
        let password = value;
        const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%§£^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]])([a-zA-Z0-9!@#$£%§^&°?\/\'\"\\~|<>,.%^*èéòçàùì()_+={}:;@\-\[\]]{8,})$/;
        return re.test(password);
    }

    validatePasswordConfirm(value) {
        let password = this.state.password;
        let passwordconfirm = value;
        return password === passwordconfirm;
    }

    handleChangeNome(event) {
        this.setState({ nome: event.target.value }, function(){
            if (this.validateNome(event.target.value)) {
                this.setState({ nomeerror: false });
            } else {
                this.setState({ nomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeCognome(event) {
        this.setState({ cognome: event.target.value }, function(){
            if (this.validateCognome(event.target.value)) {
                this.setState({ cognomeerror: false });
            } else {
                this.setState({ cognomeerror: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value }, function(){
            let email_is_valid = this.validateEmail(this.state.email);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
    
            if (email_is_valid) {
                this.setState({ emailerror: false });
            } else {
                this.setState({ emailerror: true });
            }
                    
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
        });     
    }

    handleChangePassword(event) {
        
        this.setState({ password: event.target.value }, function(){
            this.setState({ passwordconfirm: "" }, function(){
                let passwordisvalid = this.validatePassword(event.target.value);
                let required_field_are_filled = this.requiredFieldsAreFilled();
                let fields_are_valid = this.fieldsAreValid();

                if (passwordisvalid) {
                    this.setState({ passworderror: false });
                } else {
                    this.setState({ passworderror: true });
                }
                    
                if (required_field_are_filled && fields_are_valid) {
                    this.setState({ registerenabled: true });
                } else {
                    this.setState({ registerenabled: false });
                }

            });
        });
    }

    handleChangePasswordConfirm(event) {
        this.setState({ passwordconfirm: event.target.value }, function(){
            let passwordconfirmisvalid = this.validatePasswordConfirm(event.target.value);
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();

            if (passwordconfirmisvalid) {
                this.setState({ passwordconfirmerror: false });
            } else {
                this.setState({ passwordconfirmerror: true });
            }
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ registerenabled: true });
            } else {
                this.setState({ registerenabled: false });
            }
           
        });
    }

    handleRegisterClick(event) {

        event.preventDefault();

        var config = require('../config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        this.setState({ error: false });
        this.setState({ isloading: true });  
        const apiUrl = api_url_backend;
        let authorization = api_auth_backend;
        let email = this.state.email;
        let password = this.state.password;
        let nome = this.state.nome;
        let cognome = this.state.cognome;
        let username = this.state.nome+" "+this.state.cognome;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl+"users/register", { 
            "nome": nome,
            "cognome": cognome,
            "name": username,
            "email": email,
            "password": password
        }, settings).then(res2 => {

            this.setState({ isloading: false }, function(){
                //console.log(res2.data.data); 
            });

            if (res2.data.success) {
                this.setState({ password: ''});
                this.setState({ passwordconfirm: ''});
                this.setState({ nome: ''});
                this.setState({ cognome: ''});
                this.setState({ email: ''});
                this.setState({ registerenabled: false});
                this.setState({ isloading: false });
                this.setState({ registercompleted: true });
            } else {
                if (this._isMounted) {
                    this.setState({ error: true});
                    if (res2.data.data.code === "email_error"){
                        this.setState({ emailerror: true }); 
                    }
                    this.setState({ errorcode: res2.data.data.code });
                    this.setState({ errormessage: res2.data.data.message });
                    this.setState({ isloading: false });
                }
            }
        }).catch (error => {
            if (this._isMounted) {
                this.setState({ error: true});
                if (error.response.data.data.code === "email_error"){
                    this.setState({ emailerror: true }); 
                }
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
                this.setState({ isloading: false });
            }
        });

    }

    render() {
        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;
        const email = this.state.email;
        const password = this.state.password;
        const passwordconfirm = this.state.passwordconfirm;
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const emailerror = this.state.emailerror;
        const passworderror = this.state.passworderror;
        const passwordconfirmerror = this.state.passwordconfirmerror;
        const nomeerror = this.state.nomeerror;
        const cognomeerror = this.state.cognomeerror;
        const registerenabled = this.state.registerenabled;
        const registercompleted = this.state.registercompleted;

        const withLogin = this.state.withLogin;

        return (
            <div>
                <Helmet>
                    <title>QrCode Make&Read - Registrati</title>
                    <meta name="description" content="Registrati" data-react-helmet="true"/>
                </Helmet> 
                { registercompleted && (
                    <Navigate to="/registered" />
                )}
                { (autenticato || withLogin === false) ? ( 
                    <Navigate to="/" />
                ) : (
                    <div className="App">
                        <header className="App-header">
                            <LogoTitle></LogoTitle>
                            <br></br>
                            <div className="Page-title">Registrati</div>
                            { error && (
                                <div className="Error-message">{ errormessage }</div>
                            )}
                            <div className="Form-label">
                                <label>Nome</label>
                            </div>
                            <div className="Form-control">
                                { nomeerror ? (
                                    <TextField id="nome" error helperText="Valore non valido" required variant="standard" onChange={this.handleChangeNome} defaultValue={ nome }/>
                                ) : (
                                    <TextField id="nome" required variant="standard" onChange={this.handleChangeNome} defaultValue={ nome }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Cognome</label>
                            </div>
                            <div className="Form-control">
                                { cognomeerror ? (
                                    <TextField id="cognome" error helperText="Valore non valido" required variant="standard" onChange={this.handleChangeCognome} defaultValue={ cognome }/>
                                ) : (
                                    <TextField id="cognome" required variant="standard" onChange={this.handleChangeCognome} defaultValue={ cognome }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Email</label>
                            </div>
                            <div className="Form-control">
                                { emailerror ? (
                                    <TextField id="email" error type="email" helperText="Valore non valido" required variant="standard" onChange={this.handleChangeEmail} defaultValue={ email }/>
                                ) : (
                                    <TextField id="email" type="email" required variant="standard" onChange={this.handleChangeEmail} defaultValue={ email }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Password</label>
                            </div>
                            <div className="Form-control">
                                { passworderror ? (
                                    <TextField id="password" error type="password" helperText="Valore non valido" required variant="standard" onChange={this.handleChangePassword} defaultValue={ password }/>
                                ) : (
                                    <TextField id="password" type="password" required variant="standard" onChange={this.handleChangePassword} defaultValue={ password }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Conferma Password</label>
                            </div>
                            <div className="Form-control">
                                { passwordconfirmerror ? (
                                    <TextField id="passwordconfirm" error type="password" helperText="Valore non valido" required variant="standard" onChange={this.handleChangePasswordConfirm} defaultValue={ passwordconfirm }/>
                                ) : (
                                    <TextField id="passwordconfirm" type="password" required variant="standard" onChange={this.handleChangePasswordConfirm} defaultValue={ passwordconfirm }/>
                                )}
                            </div>
                            <div className="Form-control">
                                { registerenabled ? (
                                    <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleRegisterClick}>REGISTRATI</Button>
                                ) : (
                                    <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleRegisterClick}>REGISTRATI</Button>
                                )}    
                            </div>
                            <div className="separator"></div>
                        </header>
                    </div>
                )}
            </div>
        );

    }

}

export default Register;