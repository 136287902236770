import { Component } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LogoTitle from './LogoTitle';
import { Helmet } from "react-helmet";
import { Navigate, Link } from "react-router-dom";
import {Buffer} from 'buffer';

class Login extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            email : "",
            password : "",
            authorization: Cookies.get('authorization') || '',
            
            error: false,
            errormessage: '',
            isloading: false,
            loginenabled: false,
            emailerror: false,

            withLogin: false
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }   
        var config = require('../config.json');
        var withLogin = config.with_login;
        this.setState({ withLogin: withLogin});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validateEmail() {
        let email = this.state.email;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value }, function(){
            if (this.validateEmail()) {
                this.setState({ emailerror: false });
            } else {
                this.setState({ emailerror: true });
            }
            if (event.target.value.length > 0 && this.state.password.length > 0 && this.validateEmail()) {
                this.setState({ loginenabled: true });
            } else {
                this.setState({ loginenabled: false });
            }
        });
    }

    handleChangePassword(event) {
        this.setState({ password: event.target.value }, function(){
            if (event.target.value.length > 0 && this.state.email.length > 0 && this.validateEmail()) {
                this.setState({ loginenabled: true });
            } else {
                this.setState({ loginenabled: false });
            }
        });
    }

    handleLoginClick(event) {
        event.preventDefault();
        this.setState({ error: false });
        this.setState({ isloading: true });

        var config = require('../config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];

        const apiUrl = api_url_backend + 'users/auth';
        let email = this.state.email;
        let password = this.state.password;

        let b = Buffer.from(email+":"+password);
        let authorization = b.toString('base64');

        const settings = {
            headers: { Authorization: `Bearer ${api_auth_backend}` }
        };

        axios.post(apiUrl, {
            'email': email,
            'password': password
        }, settings).then(res => {
            this.setState({ isloading: false });
            if (res.data.success) {
                Cookies.set('authorization', authorization);
                Cookies.set('user_email', email);
                Cookies.set('nome', res.data.data.nome);
                Cookies.set('cognome', res.data.data.cognome);
                this.setState({ email: ''});
                this.setState({ password: ''});
                if (this._isMounted) {
                    this.setState({ authorization: authorization });
                    this.setState({ user_email: email });
                    this.setState({ nome: res.data.data.nome });
                    this.setState({ cognome: res.data.data.cognome });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ error: true});
                    this.setState({ errorcode: res.data.data.code });
                    this.setState({ errormessage: res.data.data.message });
                }
            }
        }) 
        .catch (error => {
            this.setState({ isloading: false });
            if (this._isMounted) {
                this.setState({ error: true});
                this.setState({ errorcode: error.response.data.data.code });
                this.setState({ errormessage: error.response.data.data.message });
            }
        });   
    }

    render() {
        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;
        const email = this.state.email;
        const password = this.state.password;
        const loginenabled = this.state.loginenabled;
        const emailerror = this.state.emailerror;

        const withLogin = this.state.withLogin;

        return (
            <div>
                <Helmet>
                    <title>QrCode Make&Read - Login</title>
                    <meta name="description" content="Accedi" data-react-helmet="true"/>
                </Helmet> 
                { (autenticato || withLogin === false) ? ( 
                    <Navigate to="/" /> 
                ) : (
                    <div className="App">
                        <header className="App-header">
                            <LogoTitle></LogoTitle>
                            <br></br>
                            <div className="Page-title">Accedi</div>
                            { error && (
                            <div className="Error-message">{ errormessage }</div>
                            )}
                            <div className="Form-label">
                                <label>E-mail</label>
                            </div>
                            <div className="Form-control">
                                { emailerror ? (
                                    <TextField id="email" type="email" error helperText="Valore non valido" required variant="standard" onChange={this.handleChangeEmail} defaultValue={ email }/>
                                ) : (
                                    <TextField id="email" type="email" required variant="standard" onChange={this.handleChangeEmail} defaultValue={ email }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Password</label>
                            </div>
                            <div className="Form-control">
                                <TextField id="password" type="password" required variant="standard" onChange={this.handleChangePassword} defaultValue={ password }/>
                            </div>
                            <div className="Form-link PasswordReset">
                                <Link to="/passwordresetlink">
                                    Recupera password
                                </Link>
                            </div>
                            <div className="Form-control">
                                { loginenabled ? (
                                    <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleLoginClick}>ACCEDI</Button>
                                ) : (
                                    <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleLoginClick}>ACCEDI</Button>
                                )}    
                            </div>
                            <div className="Form-link">
                                <label>Non sei registrato?</label>
                            </div>
                            <div className="Form-control" style={{marginBottom: "50px"}}>
                                <Link to="/register">
                                    <Button size="large" className="submit" variant="contained" color="secondary">REGISTRATI</Button>
                                </Link>
                            </div>
                        </header>
                    </div>
                )}
            </div>
        );

    }

}

export default Login;