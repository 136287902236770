import { Component } from 'react';
import WelcomeBar from './WelcomeBar';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import { Navigate, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


class Home extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            anchorElUserMenu: null,

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,

            deviceType: '',
            screenOrientation: '',

            withLogin: false

        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.getDeviceType = this.getDeviceType.bind(this);
        this.getScreenOrientation = this.getScreenOrientation.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }   
        const screenOrientation = this.getScreenOrientation();
        const deviceType = this.getDeviceType();
        var config = require('../config.json');
        var withLogin = config.with_login;
        this.setState({ withLogin: withLogin});
        this.setState({ deviceType: deviceType});
        this.setState({ screenOrientation: screenOrientation});

        this.setState({ isloading: false});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        this.setState({ user_email: ''});
        this.setState({ nome: ''});
        this.setState({ cognome: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        
    }

    handleUserMenuClick(event){
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(event){
        this.setState({ anchorElUserMenu: null});
    }

    getDeviceType() {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    }

    getScreenOrientation(){
        var w = window.innerWidth;
        var h = window.innerHeight;
        console.log(w);
        console.log(h);
        if (w > h) {
          var or = "landscape";
        } else {
          var or = "portrait";
        }
        return or;
    }

    render() {
        const autenticato = this.state.authorization !== '';
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const avatarletter = nome.charAt(0);
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const isloading = this.state.isloading;
        const error = this.state.error;
        const errormessage = this.state.errormessage;

        const deviceType = this.getDeviceType();
        const screenOrientation = this.getScreenOrientation();
        const withLogin = this.state.withLogin;

        return (
            <div>
                { ((withLogin && autenticato) || (withLogin === false)) && (
                    <Helmet>
                        <title>QrCode Make&Read - Homepage</title>
                        <meta name="description" content="Homepage" data-react-helmet="true"/>
                    </Helmet> 
                )}
                {(((withLogin && autenticato) || withLogin === false) && isloading === false) && ( 
                <div className="App">
                    <header className="App-header-home">
                        <WelcomeBar avatarletter={avatarletter} logout={this.handleLogoutClick}></WelcomeBar>
                    </header>
                    { screenOrientation === "portrait" && (
                        <Paper className="App-body">
                            <Link to="/make">
                                <Box className="HomeCard" style={{height: "calc(50vh - 110px)"}}>Crea QrCode</Box>
                            </Link>
                            <Link to="/read">
                                <Box className="HomeCard" style={{height: "calc(50vh - 110px)", marginBottom: "0px"}}>Scansiona QrCode</Box>
                            </Link>
                        </Paper>
                    )}
                    { screenOrientation === "landscape" && (
                        <Paper className="App-body">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Link to="/make">
                                        <Box className="HomeCard" style={{height: "80vh", marginBottom: "0px"}}>Crea QrCode</Box>
                                    </Link>    
                                </Grid>
                                <Grid item xs={6}>
                                    <Link to="/read">
                                        <Box className="HomeCard" style={{height: "80vh", marginBottom: "0px"}}>Scansiona QrCode</Box>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}                    
                </div>
                )}
                { (withLogin && autenticato === false) && ( 
                    <Navigate to="/login" />
                )}
            </div>
        );
    }
}

export default Home;