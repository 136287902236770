import { Component, Fragment } from 'react';
import logo from '../icons/logo512.png';

class LogoTitle extends Component {

    render() {

        return (
            <Fragment>
                <div className="Logo-Bar">
                    <img src={logo} alt="logo" height="100"></img>
                </div>
                <div className="Home-description">
                    <p><strong>QrCode Make & Read</strong></p>
                    <p>Crea, leggi e condividi Qrcode</p>
                </div>
            </Fragment>
        );
    }
}

export default LogoTitle;